import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingDetails } from '../../features/booking/bookingSlice';
import './BookingStatus.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMapMarkerAlt, faUser, faCalendarAlt, faCheck, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';

const BookingStatus = () => {

    const dispatch = useDispatch();
    const { bookingDetails, status } = useSelector((state) => state.bookings);
    const { id } = useSelector((state) => state.auth.user);

    const [activeStep, setActiveStep] = useState(1);


    useEffect(() => {
        dispatch(getBookingDetails(id));
    }, [dispatch, id]);

    // Loading state
    if (status === 'loading' || !bookingDetails) {
        return (
            <div className="loader">
                <div className="spinner"></div>
            </div>
        );
    }




    // Icons array with dynamic status and completion
    const icons = [
        {
            id: 1,
            icon: faMoneyBill1Wave,
            label: 'Booking Status',
            detail: 'Payment' || 'N/A',
            status: 'Pending',
            completed: !!bookingDetails.pickup_location
        },
        {
            id: 1,
            icon: faMapMarkerAlt,
            label: 'Pickup Location',
            detail: bookingDetails.pickup_location || 'N/A',
            status: 'Confirmed',
            completed: !!bookingDetails.pickup_location
        },
        {
            id: 2,
            icon: faCalendarAlt,
            label: 'Pickup Date',
            detail: bookingDetails.booking_date || 'N/A',
            status: 'Confirmed',
            completed: false
        },
        {
            id: 3,
            icon: faUser,
            label: 'Driver & Car Number',
            detail: bookingDetails.driver_name || 'Not Allotted',
            status: bookingDetails.driver_name ? 'Confirmed' : 'Processing',
            completed: !!bookingDetails.driver_name
        },

        {
            id: 4,
            icon: faMapMarkerAlt,
            label: 'Destination',
            detail: bookingDetails.dropoff_location || 'N/A',
            status: 'Upcoming',
            completed: false
        },
    ];

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <div className="booking-status-container">
            <Link to="/" className="back-arrowStatus">
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <div className="route-header">
                <h2 style={{ marginLeft: '1px', marginTop: '1px' }}>Booking Status</h2>
            </div>
            <div className="map-background">
                {/* Optional map visualization if available in API */}
            </div>

            <div className="route-summary">
                <div className="route-info">
                    <div>
                        <span className="station-code">{bookingDetails.pickup_location}</span>
                        {/* <span className="station-time">{icons[0].detail}</span> */}
                    </div>
                    <div className="route-duration">
                        <span>{bookingDetails.route_duration || 'To'}</span>
                    </div>
                    <div>
                        <span className="station-code">{bookingDetails.dropoff_location}</span>
                        {/* <span className="station-time">{icons[3].detail}</span> */}
                    </div>
                </div>
            </div>

            <div className="icon-list">
                {icons.map((icon) => (
                    <div
                        key={icon.id}
                        className={`icon-item ${activeStep === icon.id ? 'current' : ''} ${icon.completed ? 'completed' : ''}`}
                        onClick={() => handleStepChange(icon.id)}
                    >
                        <FontAwesomeIcon icon={icon.icon} className="icon" />
                        <div>
                            <p className="icon-label">
                                {icon.label}
                                {icon.status && (
                                    <span className={`check-circle ${icon.status.toLowerCase()}`}>
                                        <FontAwesomeIcon icon={faCheck} className="check-icon" />
                                    </span>
                                )}
                            </p>
                            <p className="icon-detail">{icon.detail}</p>
                            <span className="status-indicator">{icon.status}</span> {/* Status indicator */}
                        </div>
                    </div>
                ))}
            </div>

            <button className="buy-ticket-button">Enquire</button>
        </div>
    );
};

export default BookingStatus;
