import React, { useState } from 'react';
import './OtpLogin.css';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtpCode, verifyOtpCode } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OtpLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']);
    const [isOtpSent, setIsOtpSent] = useState(false);

    const { status, error } = useSelector((state) => state.auth);

    const showToast = (message, type = 'error') => {
        toast(message, {
            type,
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const handleSendOtp = async () => {
        if (!phone || phone.length !== 10 || !/^\d+$/.test(phone)) {
            showToast('Please enter a valid 10-digit mobile number');
            return;
        }

        try {
            await dispatch(sendOtpCode(phone)).unwrap();
            setIsOtpSent(true);
            showToast('OTP sent successfully', 'success');
        } catch (err) {
            showToast(err || 'Failed to send OTP');
        }
    };

    const handleVerifyOtp = async () => {
        if (otp.some((digit) => digit === '')) {
            showToast('Please enter the complete OTP.');
            return;
        }

        try {
            await dispatch(verifyOtpCode({ phone, otp: otp.join('') })).unwrap();
            showToast('OTP verified successfully', 'success');
            navigate('/home');
        } catch (err) {
            showToast(err || 'Invalid OTP. Please try again.');
        }
    };

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < 3 && value) {
                const nextInput = document.getElementById(`otp-input-${index + 1}`);
                if (nextInput) nextInput.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
    };

    const handleResendOtp = async () => {
        try {
            await dispatch(sendOtpCode(phone)).unwrap();
            showToast('New OTP sent successfully', 'success');
        } catch (err) {
            showToast(err || 'Failed to resend OTP. Please try again.');
        }
    };

    return (
        <div className="otp-fullscreen">
            <ToastContainer />

            {!isOtpSent && (
                <div className="otp-screen">
                    <img src="/assets/img/EnterNum.png" alt="Mobile" className="otp-image" />
                    <h2>Enter Your Mobile Number</h2>
                    <p>We will send you a confirmation code</p>
                    <div className="input-container">
                        <span className="country-code">
                            <img src="/assets/img/indiaflag.png" alt="India Flag" className="flag-icon" /> +91
                        </span>
                        <input
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Enter Mobile Number"
                            className="otp-input"
                        />
                    </div>
                    <button
                        onClick={handleSendOtp}
                        className="otp-button"
                        disabled={status === 'loading'}
                    >
                        {status === 'loading' ? 'Sending...' : 'Submit'}
                    </button>
                </div>
            )}

            {isOtpSent && (
                <div className="otp-screen">
                    <img src="/assets/img/verify.png" alt="Verification" className="otp-image" />
                    <h2>Enter Verification Code</h2>
                    <p>We are automatically detecting a code sent to {phone}</p>
                    <div className="otp-code-inputs">
                        {[...Array(4)].map((_, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                type="tel" // Updated here
                                maxLength="1"
                                value={otp[index]}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                className="otp-single-input"
                            />
                        ))}
                    </div>
                    <button
                        onClick={handleVerifyOtp}
                        className="otp-button"
                        disabled={status === 'loading'}
                    >
                        {status === 'loading' ? 'Verifying...' : 'Verify & Proceed'}
                    </button>
                    <p className="resend-link">
                        Didn't receive the OTP?{' '}
                        <span onClick={handleResendOtp} style={{ cursor: 'pointer', color: 'blue' }}>
                            Resend OTP
                        </span>
                    </p>
                </div>
            )}
        </div>
    );
};

export default OtpLogin;
