import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import './Slider1.css';

const Slider1 = () => {
  const location = useLocation();
  const { order, paymentAmount } = location.state || {};

  const [amount, setAmount] = useState(paymentAmount || '');
  const [showQRCode, setShowQRCode] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10 * 60); // 10 minutes in seconds
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const upiId = '008210345655@ybl';
  const payeeName = 'EasyRides';

  // Function to format time into MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Countdown timer logic
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [timeLeft]);

  // Function to handle UPI payment
  const handlePayment = (app) => {
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount.');
      return;
    }

    // Generate UPI URL
    const upiUrl = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
      payeeName
    )}&tn=Payment%20for%20EasyRides&am=${amount}&cu=INR`;

    let deepLink;
    switch (app) {
      case 'googlePay':
        deepLink = `intent://pay?pa=${upiId}&pn=${encodeURIComponent(
          payeeName
        )}&am=${amount}&cu=INR&url=${encodeURIComponent(
          upiUrl
        )}#Intent;scheme=upi;package=com.google.android.apps.nbu.paisa.user;end`;
        break;
      case 'phonePe':
        deepLink = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
          payeeName
        )}&tn=Payment%20for%20EasyRides&am=${amount}&cu=INR&mode=00&purpose=00`;
        break;
      case 'paytm':
        deepLink = `paytmmp://pay?pa=${upiId}&pn=${encodeURIComponent(
          payeeName
        )}&am=${amount}&cu=INR`;
        break;
      case 'bhim':
        deepLink = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
          payeeName
        )}&tn=Payment%20for%20EasyRides&am=${amount}&cu=INR`;
        break;
      default:
        deepLink = upiUrl;
        break;
    }

    // Redirect to the UPI payment app
    window.location.href = deepLink;
  };

  // Toggle QR Code visibility
  const toggleQRCode = () => {
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount.');
      return;
    }
    setShowQRCode(!showQRCode);
  };

  // Show and hide the popup
  const showPopup = () => setIsPopupVisible(true);
  const hidePopup = () => setIsPopupVisible(false);

  // Generate QR code URL
  const qrCodeUrl = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
    payeeName
  )}&am=${amount}&cu=INR&tn=Payment%20for%20EasyRides`;

  return (
    <div className="payment-page">
      <div className="header-payment">
        <span className="company-name">EasyRides</span>
        <div className="header-icons">
          <i className="icon-notification"></i>
          <i className="icon-close"></i>
        </div>
      </div>
      <div className="qr-section">
        <h3>Pay With UPI QR</h3>
        <div className="qr-container">
          <QRCodeSVG value={qrCodeUrl} size={160} includeMargin={true} />
          <div className="qr-info">
            <p>Scan the QR using any UPI app on your phone.</p>
            <p className="qr-validity">
              QR Code is valid for {formatTime(timeLeft)} minutes
            </p>
          </div>
        </div>
      </div>
      <div className="payment-methods">
        <h3>Pay With UPI App</h3>
        <div className="payment-icons">
          <button className="pay-icon" onClick={() => handlePayment('googlePay')}>
            <img src="/assets/img/icons/gpay1.png" alt="Google Pay" />
          </button>
          <button className="pay-icon" onClick={() => handlePayment('phonePe')}>
            <img src="/assets/img/icons/phonepe.png" alt="PhonePe" />
          </button>
          <button className="pay-icon" onClick={() => handlePayment('paytm')}>
            <img src="/assets/img/icons/paytm.png" alt="Paytm" />
          </button>
          <button className="pay-icon" onClick={() => handlePayment('bhim')}>
            <img src="/assets/img/icons/bhim.png" alt="Others" />
          </button>
        </div>
      </div>
      {/* Bottom Section */}
      <div className="bottom-section">
        <div className="amount-display">
          <span className="amount-symbol">₹</span>
          <span className="amount-value">{amount || '0'}</span>
        </div>
        <a href="#details" className="view-details-link" onClick={showPopup}>
          View Details
        </a>
      </div>
      {/* Popup Slider */}
      {isPopupVisible && (
        <div className="popup-slider">
          <div className="popup-content">
            <h4>Payment Details</h4>
            <p><strong>Total Amount Paid:</strong> ₹{amount || '0'}</p>
            <p><strong>Amount Paid:</strong> ₹{amount || '0'}</p>
            <p><strong>Amount Due:</strong> ₹0</p>
            <button className="close-popup-btn" onClick={hidePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider1;
