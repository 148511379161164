import React, { useState ,useEffect, useRef } from "react";
import "./OfferList.css";

// Data for Popular Cities
const popularCities = [
  { 
    name: "Purulia", 
    icon: "🏞️", 
    packages: [
      { place: "Ayodhya Hills", nights: "2 Nights", price: "₹5,999", image: "/assets/img/Favorite-place/Ayodhyapahar.jpg" },
      { place: "Saheb Bandh", nights: "1 Night", price: "₹3,499", image: "/assets/img/Favorite-place/sahebbandh.jpg" },
      { place: "Joychandi Pahar", nights: "2 Nights", price: "₹6,299", image: "/assets/img/Favorite-place/joychandipahar.png" },
      { place: "Pakhi Pahar", nights: "1 Night", price: "₹4,199", image: "/assets/img/Favorite-place/pakhipahar.jpg" },
    ]
  },
  { 
    name: "Asansol", 
    icon: "🏢", 
    packages: [
      { place: "Maithon Dam", nights: "1 Night", price: "₹4,999", image: "/assets/img/Favorite-place/maithon.png" },
      { place: "Churulia", nights: "1 Night", price: "₹3,299", image: "/assets/img/Favorite-place/churulia.png" },
      { place: "Kalyaneswari Temple", nights: "1 Night", price: "₹3,999", image: "/assets/img/Favorite-place/kaleswari.png" },
    ]
  },
  { 
    name: "Bankura", 
    icon: "🏞️", 
    packages: [
      { place: "Susunia Hill", nights: "2 Nights", price: "₹6,599", image: "/assets/img/Favorite-place/susunia.png" },
      { place: "Bishnupur Temples", nights: "2 Nights", price: "₹5,499", image: "/assets/img/Favorite-place/bisnupurtemple.png" },
      { place: "Mukutmanipur", nights: "2 Nights", price: "₹6,999", image: "/assets/img/Favorite-place/mukutmanipur.png" },
      { place: "Jhilimili Forest", nights: "1 Night", price: "₹4,499", image: "/assets/img/Favorite-place/jhilimi.png" },
    ]
  },
  { 
    name: "Tatanagar", 
    icon: "🚉", 
    packages: [
      { place: "Jubilee Park", nights: "1 Night", price: "₹3,999", image: "/assets/img/Favorite-place/Jubilee.png" },
      { place: "Dalma Wildlife Sanctuary", nights: "2 Nights", price: "₹7,299", image: "/assets/img/Favorite-place/Dalma.png" },
      { place: "Dimna Lake", nights: "1 Night", price: "₹4,299", image: "/assets/img/Favorite-place/dimna.png" },
      { place: "Hudco Lake", nights: "1 Night", price: "₹3,699", image: "/assets/img/Favorite-place/hudco.png" },
    ]
  },
  { 
    name: "Ranchi", 
    icon: "🏞️", 
    packages: [
      { place: "Hundru Falls", nights: "1 Night", price: "₹5,499", image: "/assets/img/Favorite-place/hundru.png" },
      { place: "Patratu Valley", nights: "2 Nights", price: "₹6,999", image: "/assets/img/Favorite-place/patratu1.png" },
      { place: "Jonha Falls", nights: "1 Night", price: "₹4,299", image: "/assets/img/Favorite-place/jonha.png" },
    ]
  },
  
  
  
  {
    name: "Kolkata",
    icon: "🌆",
    packages: [
      { place: "Victoria Memorial", nights: "1 Night", price: "₹5,999", image: "/assets/img/Favorite-place/victoria1.png" },
      { place: "Howrah Bridge", nights: "1 Night", price: "₹4,299", image: "/assets/img/Favorite-place/howrah.png" },
      { place: "Eco Park", nights: "1 Night", price: "₹3,999", image: "/assets/img/Favorite-place/ecopark.png" },
      { place: "Dakshineswar Kali Temple", nights: "1 Night", price: "₹4,499", image: "/assets/img/Favorite-place/Dakshineswar.png" },
      { place: "Indian Museum", nights: "1 Night", price: "₹5,199", image: "/assets/img/Favorite-place/IndianMuseum.png" },
      { place: "Science City Kolkata", nights: "1 Night", price: "₹3,899", image: "/assets/img/Favorite-place/ScienceCity.png" },
      { place: "Belur Math", nights: "1 Night", price: "₹3,699", image: "/assets/img/Favorite-place/BelurMath.png" },
      { place: "Princep Ghat", nights: "1 Night", price: "₹3,999", image: "/assets/img/Favorite-place/PrinsepGhat.png" },
      { place: "Birla Planetarium", nights: "1 Night", price: "₹4,599", image: "/assets/img/Favorite-place/BirlaPlanetarium.png" },
    ]
  }
  
];
// Extend Popular Cities Data with Favorite Routes
const favoriteRoutes = {
  Purulia: [
    { from: "Purulia", to: "Ayodhaya pahar", distance: "54 km", time: "1 hrs 30 mins" },
    { from: "Purulia", to: "Joychandi Pahar", distance: "40 km", time: "1 hrs" },
    { from: "Purulia", to: "Ranchi", distance: "130 km", time: "3 hrs 30 mins" },
    { from: "Purulia", to: "Tatanagar", distance: "85 km", time: "2.30 hrs" },
    { from: "Purulia", to: "Muri", distance: "65 km", time: "2 hrs" },
    { from: "Purulia", to: "Asansol", distance: "125 km", time: " 3 hrs" },
  ],
  Asansol: [
    { from: "Asansol", to: "Durgapur", distance: "30 km", time: "45 mins" },
    { from: "Asansol", to: "Kolkata", distance: "200 km", time: "4 hrs" },
  ],
  Bankura: [
    { from: "Bankura", to: "Purulia", distance: "90 km", time: "2 hrs" },
    { from: "Bankura", to: "Kolkata", distance: "190 km", time: "4 hrs" },
  ],
  Tatanagar: [
    { from: "Tatanagar", to: "Ranchi", distance: "130 km", time: "3 hrs" },
    { from: "Tatanagar", to: "Purulia", distance: "85 km", time: "2 hrs" },
  ],
  Ranchi: [
    { from: "Ranchi", to: "Purulia", distance: "160 km", time: "3 hrs 30 mins" },
    { from: "Ranchi", to: "Tatanagar", distance: "130 km", time: "3 hrs" },
  ],
  Kolkata: [
    { from: "Kolkata", to: "Asansol", distance: "200 km", time: "4 hrs" },
    { from: "Kolkata", to: "Bankura", distance: "190 km", time: "4 hrs" },
  ],
};


// MyCoupons Component as a Slider
const MyCoupons = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const coupons = [
    {
      imgSrc: '/assets/img/PROMO3.png',
      title: 'First Booking with EasyRides',
      description: 'Get ₹100 Off*',
      subtext: 'Minimum fare ₹3000',
      link: '/easyrides-first-booking',
      cta: 'Apply Now',
    },  
    {
      imgSrc: '/assets/img/PROMO4.png',
      title: 'First Booking with EasyRides',
      description: 'Get ₹200 Off*',
      subtext: 'Minimum fare ₹6000',
      link: '/easyrides-first-booking',
      cta: 'Apply Now',
    },
  
  ];

  // Handle Next and Previous Slide
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % coupons.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + coupons.length) % coupons.length);
  };
  
  return (
    <div className="my-coupons-container">
      <h2 className="my-coupons-title">My Coupons</h2>
      <div className="coupon-slider">
        <button className="slider-btn prev-btn" onClick={handlePrev}>❮</button>
        
        <div className="coupon-card">
          <img src={coupons[currentIndex].imgSrc} alt={coupons[currentIndex].title} className="coupon-image" />
          <div className="coupon-details">
            <h4 className="coupon-title">{coupons[currentIndex].title}</h4>
            <p className="coupon-description">{coupons[currentIndex].description}</p>
            <p className="coupon-subtext">{coupons[currentIndex].subtext}</p>
            <a href={coupons[currentIndex].link} className="coupon-cta">{coupons[currentIndex].cta}</a>
          </div>
        </div>

        <button className="slider-btn next-btn" onClick={handleNext}>❯</button>
      </div>
    </div>
  );
};

// OfferList Component
const OfferList = () => {
  const [search, setSearch] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const packagesGridRef = useRef(null);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
  };
  const handleBackClick = () => {
    setSelectedCity(null);
  };

  return (
    <div className="offer-list-container">
      <div className="header">
        <button 
          className="back-btn" 
          onClick={() => {
            if (selectedCity) {
              handleBackClick(); // Redirect back to Cities
            } else {
              window.location.href = "/"; // Redirect to Home Page
            }
          }}
        >
          ← {selectedCity ? "Back to Cities" : "Home"}
        </button>
      </div>


      {/* Popular Cities Section */}
      {!selectedCity && (
        <>
          <div className="popular-cities">
            <h3 style={{marginTop:'10px' , marginBottom:'10px'}}>Popular Cities</h3>
            <div className="cities-grid">
              {popularCities.map((city, index) => (
                <div key={index} className="city-item" onClick={() => handleCityClick(city)}>
                  <div className="city-icon">{city.icon}</div>
                  <span className="city-name">{city.name}</span>
                </div>
              ))}
            </div>
          </div>

          {/* My Coupons Section */}
          <MyCoupons />
        </>
      )}

      {/* City Packages Section */}
      {selectedCity && (
        <>
          <div className="city-packages">
              <h3>{selectedCity.name} Top Visit</h3>
              <div className="packages-grid">
                {selectedCity.packages.map((pkg, index) => (
                  <div key={index} className="package-card">
                    <div className="package-image-container">
                      {/* <button className="arrow-btn left">←</button> */}
                      <img src={pkg.image} alt={pkg.place} className="package-image" />
                      {/* <button className="arrow-btn right">→</button> */}
                    </div>
                    <div className="package-info">
                      <h4 className="package-title">{pkg.place}</h4>
                      {/* <p className="package-price">From {pkg.price}</p> */}
                      {/* <button className="explore-btn">Explore →</button> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>


          {/* Favorite Routes Section */}
          <div className="favorite-routes">
          <h3>Favorite Routes from {selectedCity.name}</h3>
          <div className="routes-slider">
            {favoriteRoutes[selectedCity.name]?.map((route, index) => (
              <div key={index} className="route-card">
                <div className="route-details">
                  <h4 className="route-title">
                    {route.from} → {route.to}
                  </h4>
                  <p className="route-info">
                    Distance: <strong>{route.distance}</strong>
                  </p>
                  <p className="route-info">
                    Estimated Time: <strong>{route.time}</strong>
                  </p>
                </div>
                <button className="apply-now">Explore Route</button>
              </div>
            ))}
          </div>
        </div>
        
        </>
      )}
    </div>
  );
};

export default OfferList;
