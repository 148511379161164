import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './BookingModal.css';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { size } from 'lodash';

const BookingModal = ({
    modalIsOpen,
    closeModal,
    selectedVehicle,
    selectedDate,
    selectedTime,
    setSelectedDate,
    setSelectedTime,
    pickup,
    destination,
    fareDetails,
    pickupCoordinates,
    destinationCoordinates
}) => {
    const navigate = useNavigate();
    const { control, handleSubmit, formState: { errors }, setValue, register } = useForm();
    const [paymentOption, setPaymentOption] = useState('full'); // State for payment option
    const user = useSelector((state) => state.auth.user);
    const [finalAmount, setFinalAmount] = useState(fareDetails.totalFare); // State to hold the calculated amount
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(true); // State for payment success modal
    const [paymentId, setPaymentId] = useState('');
    const [promoApplied, setPromoApplied] = useState(false); // State to track if promo code is applied
    const [promoCode, setPromoCode] = useState(''); // State to capture promo code input


    //   // Function to apply promo code
    //     const applyPromoCode = () => {
    //         if (fareDetails.totalFare > 3000 && !promoApplied && promoCode === 'EASYRIDES') {
    //         setPromoApplied(true);
    //         setFinalAmount((prev) => prev - 100); // Deduct 100 INR
    //         } else {
    //         alert("Invalid promo code or promo already applied!");
    //         }
    //     };

    // Apply promo code and reduce the fare by 100 INR if applicable

    // Function to handle closing the modal and resetting promo-related states
    const handleCloseModal = () => {
        setPromoApplied(false);                // Reset promo applied status
        setPromoCode('');                      // Clear the promo code input
        setFinalAmount(fareDetails.totalFare); // Reset the final amount to original fare
        setPaymentOption('full');              // Reset payment option to default
        closeModal();                          // Call the original close modal function
    };

    // Function to handle promo code application
    const applyPromoCode = () => {
        if (promoCode.trim() === "") {
            alert("Please enter a promo code.");
            return;
        }

        if (promoCode !== "EASYRIDES") {
            alert("Invalid Promo Code. Please enter the correct code.");
            return;
        }

        if (promoApplied) {
            alert("Promo code already applied.");
            return;
        }

        // Apply discount if all conditions are met
        if (fareDetails.totalFare >= 3000) {
            setFinalAmount(finalAmount - 100);
            setPromoApplied(true);
        } else {
            alert("Promo code applicable only for fares above 3000.");
        }
    };



    // Sync form fields with user data
    useEffect(() => {
        if (user) {
            setValue('name', user.name);
            setValue('email', user.email);
            setValue('phone', user.phone);
            setValue('address', user.address);
        }
    }, [user, setValue]);

    // Sync form fields with selected date and time
    useEffect(() => {
        if (modalIsOpen) {
            setValue('date', selectedDate);
            setValue('time', selectedTime);
        }
    }, [modalIsOpen, selectedDate, selectedTime, setValue]);

    // Adjust the payment amount based on selected option and promo application
    useEffect(() => {
        const baseAmount = promoApplied ? fareDetails.totalFare - 100 : fareDetails.totalFare;
        const amount = paymentOption === '30%' ? baseAmount * 0.3 : baseAmount;
        setFinalAmount(Math.round(amount));
    }, [paymentOption, fareDetails.totalFare, promoApplied]);


    // Format time to a readable format
    const formatTime = (dateString) => {
        const date = new Date(dateString);
        return date.toTimeString().split(' ')[0];
    };

    // Format date to a readable format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    // Handle payment
    const handlePayment = async (order) => {
        const options = {
            //key: process.env.REACT_APP_RAZORPAY_KEY, // Use environment variable for the key
            key: 'rzp_test_1WxVEadb1LnUsb',
            amount: order.amount_paid * 100,
            currency: order.currency,
            name: 'EasyRides',
            description: 'Booking Payment',
            handler: async (response) => await handlePaymentSuccess(response), // Call the payment success function after payment
            prefill: {
                name: user.name,
                email: user.email,
                contact: user.phone,
            },
            notes: {
                booking_id: order.id,
                address: user.address,
            },
            theme: {
                color: '#F37254',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    };

    // Payment success handler (async)
    const handlePaymentSuccess = async (response) => {
        try {
            setIsPaymentSuccess(true);
            console.log('Payment Successful:', response);
            setPaymentId(response.razorpay_payment_id);

            // Send payment details to backend for verification and saving
            await axios.post('http://127.0.0.1:8000/api/payment-success', {
                razorpay_payment_id: response.razorpay_payment_id,
            });


            // Show payment success modal or perform any additional actions
            document.querySelector('.payment-success-modal').classList.add('show');
            // document.querySelector('.payment-success-modal').classList.add('show');

            console.log('Payment details saved successfully');
        } catch (error) {
            console.error('Error handling payment success:', error);
        }
    };


    // // Handle form submission
    const onSubmit = async (data) => {
        const api = axios.create({
            // baseURL: "http://127.0.0.1:8000",
            baseURL: "https://devgas.astergo.in",
        });

        try {
            const formattedDate = formatDate(selectedDate);
            const formattedTime = formatTime(selectedTime);

            // Calculate the final amount based on the payment option
            // const paymentAmount = paymentOption === '30%' ? fareDetails.totalFare * 0.3 : fareDetails.totalFare;
            const paymentAmount = paymentOption === '30%'
                ? Math.round(fareDetails.totalFare * 0.3)
                : Math.round(fareDetails.totalFare);

            const bookingResponse = await api.post('/api/bookings', {
                ...data,
                vehicle_id: selectedVehicle.id,
                booking_date: formattedDate,
                pickup_time: formattedTime,
                pickup_location: pickup,
                dropoff_location: destination,
                user_id: user.id,
                pickup_lat_long: pickupCoordinates,
                dropoff_lat_long: destinationCoordinates,
                fair_amount: fareDetails.totalFare,
                payment_amount: finalAmount, // Include payment amount
                payment_option: paymentOption // Include payment option
            });

            const { order } = bookingResponse.data;

            console.log('order:', order);

            if (order) {
                navigate('/payment', { state: { order, paymentAmount } });
                // alert('Booking successful! Please pay manually using the UPI ID provided.');
                // handleCloseModal();
                // await handlePayment(order);
            }
        } catch (error) {
            console.error('Error submitting booking:', error);
        }
    };

    // const onSubmit = async (data) => {
    //     const api = axios.create({
    //         baseURL: "http://127.0.0.1:8000",
    //         // baseURL: "https://devgas.astergo.in", // Your backend URL
    //     });

    //     try {
    //         const formattedDate = formatDate(selectedDate);
    //         const formattedTime = formatTime(selectedTime);

    //         // Calculate the final payment amount
    //         const paymentAmount = paymentOption === '30%'
    //             ? Math.round(fareDetails.totalFare * 0.3)
    //             : Math.round(fareDetails.totalFare);

    //         // Step 1: Create booking
    //         const bookingResponse = await api.post('/api/bookings', {
    //             ...data,
    //             vehicle_id: selectedVehicle.id,
    //             booking_date: formattedDate,
    //             pickup_time: formattedTime,
    //             pickup_location: pickup,
    //             dropoff_location: destination,
    //             user_id: user.id,
    //             pickup_lat_long: pickupCoordinates,
    //             dropoff_lat_long: destinationCoordinates,
    //             fair_amount: fareDetails.totalFare,
    //             payment_amount: paymentAmount, // Include payment amount
    //             payment_option: paymentOption // Include payment option
    //         });

    //         const bookingData = bookingResponse.data.data;

    //         console.log('bookingData:', bookingData);

    //         if (bookingData) {
    //             // Step 2: Initiate payment with PhonePe
    //             const paymentResponse = await api.post('/api/payment/initiate', {
    //                 order_id: bookingData.id,
    //                 amount: paymentAmount * 100, // Amount in paise
    //             });

    //             const { paymentUrl } = paymentResponse.data;

    //             if (paymentUrl) {
    //                 // Step 3: Redirect user to PhonePe payment page
    //                 window.location.href = paymentUrl;
    //             } else {
    //                 console.error('Payment URL not received from server');
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error submitting booking:', error);
    //     }
    // };






    return modalIsOpen ? (
        <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>


                <section className="booking-section">
                    <div className="container">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-booking" role="tabpanel" aria-labelledby="pills-booking-tab">
                                <div className="booking-details card shadow-sm p-2 rounded">
                                    <h5></h5>
                                    <button className="close-button-book" onClick={handleCloseModal} >&times;</button>
                                    <div className="modal-header">

                                    </div>
                                    <div className="booking-title text-center mb-4">
                                        <h3 className="font-weight-bold" style={{ color: '#6739b7', fontWeight: 'bold' }}>Booking Details</h3>
                                    </div>
                                    <div className="row booking-info text-center">
                                        <div className="col-md-4 col-sm-6 pickup-address mb-4">
                                            <h5 className="text" style={{ color: '#23ce1b' }}><i className="fas fa-map-marker-alt me-2"></i>Pickup</h5>
                                            <p className="font-weight-bold">{pickup}</p>
                                        </div>
                                        <div className="col-md-4 col-sm-6 drop-address mb-4">
                                            <h5 className="text" style={{ color: 'red', marginTop: '1px' }}><i className="fas fa-location-arrow me-2"></i>Drop Off</h5>
                                            <p className="font-weight-bold">{destination}</p>
                                        </div>
                                        <div className="terms-section mt-4 text-center" >
                                            <h6 style={{ marginTop: '1px', color: 'black' }}>Terms & Conditions</h6>
                                            <p className="text" >
                                                Book your cab at least <strong>8 hours</strong> before departure within Purulia.
                                                For other cities, please book at least <strong>12 hours</strong> before your selected date.
                                            </p>
                                        </div>



                                    </div>
                                    <div className="booking-form">
                                        <div className="booking-title text-center mb-4">
                                            <h5 className="font-weight-bold " style={{ color: '#6739b7' }}>Enter Below Details</h5>
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            {/* Existing form fields remain unchanged */}
                                            <div className="row input-row">
                {/* Pickup Date */}
                <div className="col-md-6 input-block">
                    <label>Pickup Date</label>
                    <Controller
                        control={control}
                        name="date"
                        render={({ field }) => (
                            <DatePicker
                                className="form-control datetimepicker"
                                placeholderText="Select date"
                                onChange={(date) => {
                                    field.onChange(date);
                                    setSelectedDate(date);
                                }}
                                selected={selectedDate}
                                dateFormat="MMMM d, yyyy"
                            />
                        )}
                        rules={{ required: true }}
                    />
                    {errors.date && <span className="error-message">This field is required</span>}
                </div>

                {/* Pickup Time */}
                <div className="col-md-6 input-block">
                    <label>Pickup Time</label>
                    <Controller
                        control={control}
                        name="time"
                        render={({ field }) => (
                            <DatePicker
                                className="form-control datetimepicker"
                                placeholderText="Select time"
                                onChange={(time) => {
                                    field.onChange(time);
                                    setSelectedTime(time);
                                }}
                                selected={selectedTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                            />
                        )}
                        rules={{ required: true }}
                    />
                    {errors.time && <span className="error-message">This field is required</span>}
                </div>
            </div>


                                            <div className="input-row">
                                                {/* Enter Name */}
                                                <div className="input-block">
                                                    <label>Enter Name <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        {...register('name', { required: true })}
                                                        className="form-control"
                                                        placeholder="Your Name"
                                                    />
                                                    {errors.name && <span className="error">This field is required</span>}
                                                </div>

                                                {/* Phone Number */}
                                                <div className="input-block">
                                                    <label>Phone Number <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        {...register('phone', { required: true })}
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                    />
                                                    {errors.phone && <span className="error">This field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-block">
                                                    <label>Enter Email ID <span className="text-danger">*</span></label>
                                                    <input type="text" {...register('email', { required: true })} className="form-control" placeholder="Your Email" />
                                                    {errors.email && <span className="error">This field is required</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-block">
                                                    <label>Enter Address<span className="text-danger">*</span></label>
                                                    <input type="text" {...register('address', { required: true })} className="form-control" placeholder="Your Address" />
                                                    {errors.address && <span className="error">This field is required</span>}
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div
                                                        className="payment-options-container"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            gap: '8px',
                                                            marginBottom: '10px',
                                                        }}
                                                    >
                                                        {/* Full Payment Option */}
                                                        <div
                                                            className={`payment-box ${paymentOption === 'full' ? 'active' : ''}`}
                                                            onClick={() => setPaymentOption('full')}
                                                            style={{
                                                                cursor: 'pointer',
                                                                flex: 1,
                                                                padding: '12px 16px',
                                                                border: '1px solid #1FAB89',
                                                                borderRadius: '30px',
                                                                textAlign: 'center',
                                                                background: paymentOption === 'full'
                                                                    ? 'linear-gradient(135deg, #1FAB89, #28C76F)'
                                                                    : '#F9FAFB',
                                                                color: paymentOption === 'full' ? '#fff' : '#1FAB89',
                                                                fontWeight: '600',
                                                                transition: 'all 0.3s ease',
                                                                boxShadow:
                                                                    paymentOption === 'full'
                                                                        ? '0 4px 8px rgba(31, 171, 137, 0.2)'
                                                                        : '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                                fontSize: '14px',
                                                            }}

                                                        >
                                                            <h4
                                                                style={{
                                                                    margin: 0, fontSize: '15px', fontWeight: '700', color: paymentOption === 'full' ? '#fff' : '#1FAB89', // Dynamic text color
                                                                }}
                                                            >
                                                                Full Payment     (100%)
                                                            </h4>
                                                        </div>

                                                        {/* 30% Payment Option */}
                                                        <div
                                                            className={`payment-box ${paymentOption === '30%' ? 'active' : ''}`}
                                                            onClick={() => setPaymentOption('30%')}
                                                            style={{
                                                                cursor: 'pointer',
                                                                flex: 1,
                                                                padding: '12px 16px',
                                                                border: '1px solid #625D5B',
                                                                borderRadius: '30px',
                                                                textAlign: 'center',
                                                                background: paymentOption === '30%'
                                                                    ? 'linear-gradient(135deg, #FF5722, #FF8A50)'
                                                                    : '#F9FAFB',
                                                                color: paymentOption === '30%' ? '#fff' : '#514B4C',
                                                                fontWeight: '600',
                                                                transition: 'all 0.3s ease',
                                                                boxShadow:
                                                                    paymentOption === '30%'
                                                                        ? '0 4px 8px rgba(255, 87, 34, 0.2)'
                                                                        : '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    margin: 0, fontSize: '15px', fontWeight: '700', color: paymentOption === '30%' ? '#fff' : '#514B4C', // Dynamic text color
                                                                }}
                                                            >
                                                                Mini Payment  (30%)
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-4 col-sm-6 booking-amount mb-4">
                                                <div className="payment-summary card">
                                                    <div className="payment-header">
                                                        <i className="fas fa-money-bill-wave icon"></i>
                                                        <h5 className="payment-title" >Total Amount to be Paid</h5>
                                                    </div>
                                                    <div className="payment-details">
                                                        <h6 className="total-amount" style={{ fontSize: '28px', color: '#532E1C' }}>
                                                            ₹ {promoApplied ? fareDetails.totalFare - 100 : fareDetails.totalFare}
                                                            <i className="fas fa-info-circle info-icon"></i>
                                                        </h6>
                                                    </div>
                                                    <hr />
                                                    <div className="advance-details">
                                                        <div className="advance-payment">
                                                            {/* style={{ fontSize: '13px', margin: '-10', fontWeight: '600',fontFamily:'sans-Serif' }} */}
                                                            <p className="label" style={{ fontSize: '12px', fontFamily: 'sans-Serif' }}>Advance Payment</p>
                                                            <h4 className="amount" style={{ fontSize: '17px', color: '#1FAB89' }}>₹ {finalAmount}</h4>
                                                        </div>
                                                        <div className="dues-payment">
                                                            <p className="label" style={{ fontSize: '13px' }}>Dues</p>
                                                            <h4 className="amount" style={{ fontSize: '18px', color: '#FF5722' }}>₹ {promoApplied ? (fareDetails.totalFare - 100) - finalAmount : fareDetails.totalFare - finalAmount}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="promo-code-section" style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter promo code"
                                                        value={promoCode}
                                                        onChange={(e) => setPromoCode(e.target.value)}
                                                        disabled={promoApplied}
                                                        style={{ flex: 1, padding: '5px', marginRight: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={applyPromoCode}
                                                        disabled={promoApplied}
                                                        style={{
                                                            padding: '6px 12px',
                                                            fontSize: '16px',
                                                            borderRadius: '4px',
                                                            border: 'none',
                                                            backgroundColor: '#1FAB89',
                                                            color: '#fff',
                                                            cursor: 'pointer',
                                                            height: '36px',
                                                        }}
                                                    >
                                                        Apply
                                                    </button>
                                                </div>

                                                {/* Promo code applied message displayed below the button */}
                                                {promoApplied && (
                                                    <div style={{ marginTop: '5px', color: '#1FAB89', fontSize: '12px' }}>
                                                        Congratulations! You get ₹100 OFF on your first ride.
                                                    </div>
                                                )}
                                            </div>



                                            <hr />

                                            <div className="col-lg-6">
                                                <button type="submit" className="btn btn-success w-100">
                                                    Confirm Booking
                                                    <span style={{ fontSize: '12px', color: 'white' }}> ( ₹{finalAmount} )</span>
                                                </button>
                                            </div>
                                            <div className="col-lg-6">
                                                <button type="button" className="btn btn-danger w-100" style={{ padding: '5px', marginTop: '2px', marginBottom: '5px' }} onClick={handleCloseModal}>Cancel</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className={`payment-success-modal ${paymentId ? 'show' : ''}`} style={{ display: paymentId ? 'block' : 'none' }}>
                    <div className="modal-content">
                        <div className="modal-icon">
                            <span className="success-icon">&#10004;</span>
                        </div>
                        <h6 className="modal-title" style={{ color: 'green' }}>Payment Successful!</h6>
                        <p className="modal-message">Your booking has been confirmed.</p>
                        <ul className="transaction-details">
                            <li><strong>Transaction ID:</strong> {paymentId}</li> {/* Display the payment ID */}
                            <li><strong>Date & Time:</strong> {new Date().toLocaleString()}</li> {/* Display current date and time */}
                        </ul>
                        {/* <button className="modal-button" onClick={() => document.querySelector('.payment-success-modal').classList.remove('show')}>
                            Enjoy Your Trip !!
                        </button> */}
                        <button
                            className="modal-button"
                            onClick={() => {
                                document.querySelector('.payment-success-modal').classList.remove('show');
                                window.location.href = '/'; // Redirect to the home page
                            }}>
                            Enjoy Your Trip !!
                        </button>
                    </div>
                </div>

            </div>
        </div>
    ) : null;
};

export default BookingModal;