
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Header from '../components/Main/Header';
import GeocodeComponent from '../components/Main/GeocodeComponent';
import Slider1 from '../components/Main/Slider1';
import BottomTabBar from '../components/Common/BottomTabBar';
import DriverPartner from '../components/Driver/DriverPartner';
import CarList from '../components/Car/CarList';


const HomePage = () => {
    const token = useSelector((state) => state.auth.token);

    if (!token) {
        // return <Navigate to="/login" />;
        return <Navigate to="/OtpLogin" />;
    }

    return (
        <div>
            <Header />
            <GeocodeComponent />
            <BottomTabBar />
            {/* <Slider1/> */}
            {/* <DriverPartner/> */}
            {/* <CarList/> */}

        </div>  
    );
};

export default HomePage;
